import Web3 from "web3";
import NFT_ABI from "./Contracts/NFT_ABI.json";
import { ADDRESSES, OPENSEA_URL, OPENSEA_API_KEY } from "./Contracts/constants";
export async function setWeb3(web3) {
  window.web3 = new Web3(web3.currentProvider);
}
export async function getAccount(web3) {
  try {
    const accounts = await web3.eth.getAccounts();
    if (!accounts) {
      return "";
    }
    return accounts[0];
  } catch (err) {
    console.log(err);
    return false;
  }
}
export async function getAllTokens(address) {
  let net = await getNetwork();
  let response = await fetch(
    `https://${OPENSEA_URL[net]}/api/v1/assets?owner=${address}&asset_contract_address=${ADDRESSES[net]}&limit=50`
  , {
      headers: {
        'X-API-KEY': `${OPENSEA_API_KEY}`
      }
      });

  let res = await response.json();

  return res.assets;
}

export async function getTokenURI(tokenID) {
  try {
    let net = await getNetwork();
    return await getterContract(ADDRESSES[net], "tokenURI", [tokenID]);
  } catch (err) {
    console.log(err);
    return "";
  }
}
export const getNetwork = async () => {
  try {
    let web3 = await getWeb3();
    const network = await web3.eth.net.getId();
    if (network) return network;

    return 1;
  } catch (e) {
    console.log(e);
    return 1;
  }
};
export async function getWeb3() {
  try {
    if (window.web3 && window.web3.currentProvider) {
      return window.web3;
    } else {
      throw "No web3 connection";
    }
  } catch (err) {
    console.log(err);
  }
}
export async function getDeployedContract(address) {
  let web3 = await getWeb3();
  return new web3.eth.Contract(NFT_ABI, address);
}

export const getterContract = async (address, methodName, params) => {
  try {
    const contractInstance = await getDeployedContract(address);

    return await contractInstance.methods[methodName](...params)
      .call()
      .then((res) => {
        return res;
      });
  } catch (e) {
    throw e;
  }
};

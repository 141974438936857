import React, { useEffect, useState } from 'react';
import "../../../style.css";
import { isAuthenticated } from '../../../requests';
import Select from "react-select";
import makeAnimated from 'react-select/animated'
import { Instance, setAuthToken } from '../../../axios';
import requests from "../../../request"
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const CategoryBar = ({history, selectedCategory, handleSelect,reload}) => {

    let {obj:{data}} = isAuthenticated()
    let token = data

    const animatedComponents = makeAnimated();

    const [categories, setCategories] = useState([])
    

    const fetchCategories = async () => {
        
        setAuthToken(token)
        const response = await Instance.get(`${requests.fetchAllCategory}`).catch((error) => {
            if(error.response){
                toast.error(error.response.data.error.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        })

        if(response && response.data) {
            let obj = [];
            response.data.data.forEach((category)=>{
                obj.push({value:`${category["categoryId"]}`,label:`${category["category"]["categoryName"]}`});
            })
            setCategories(obj)
        }
    }

    useEffect(() => {
        fetchCategories()
    }, [reload])

    

    const display = () => (
        <div className="row mx-0">
            <div className="col-lg"></div>
            <div className="col-lg">
                <Select
                    className="basic-single"
                    // classNamePrefix="select"
                    value={selectedCategory}
                    onChange={handleSelect}                   
                    isSearchable
                    name="color"
                    options={categories}
                    theme={theme => ({
                        ...theme,
                        colors: {
                            ...theme.colors,
                            primary25: "#e0e0e2",
                            primary: "#88888f",
                        }
                    })}
                />
            </div>
            <div className="col-lg">
                <button
                    className="btn btn-success categoryButton"
                    data-toggle="modal" data-target="#exampleModal"
                >ADD NEW CATEGORY</button>
            </div>
            <div className="col-lg"></div>
        </div>
    )

    return (
        <div className="alert alert-info p-0" style={{backgroundColor:"#e0e0e2"}}>
            {display()}
            {/* <ToastContainer autoClose={2000}/> */}
        </div>
    )
}

export default CategoryBar;

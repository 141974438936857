const requests = {
	fetchLogin: "/auth/login",
	skipOtp:"/auth/skipOtpLogin",
	updateUser: "/auth/updateuser",
	otpVerification: "/auth/verifyotp",

	fetchAllOwners: "/admin/allOwners",

	postAddSubheadings: "/admin/addSubheading",
	postAddHeadings: "/admin/addHeading",
	postAddCategory: "/admin/addCategory",
	fetchCategoryById: "/admin/categoryById",
	fetchAllCategory: "/admin/allcategory",
	fetchAllCategoryList: "/admin/allCategory",
	fetchAllHeadings: "/admin/allheading",
	fetchAllSubHeadings: "/admin/allsubheading",

	postAddTalent: "/admin/addTalents",
	updateTalentById: "/admin/updateTalents",
	fetchAllTalents: "/admin/allTalents",
	fetchAllTalentsByCategoryId: "/admin/categoryByTalents",
	fetchTalentById: "/admin/talentsById",
	deleteTalentById: "/admin/removeTalents",

	fetchRewardById: "/admin/rewardsById",
	fetchAllRewards: "/admin/allRewards",
	updateRewardById: "/admin/updateRewards",
	postAddReward: "/admin/addRewards",
	deleteRewardById: "/admin/removeRewards",
	sendReward: "/admin/sendRewards",
	claimReward: "/user/claimReward",

	fetchAllOwners: "/admin/allOwners",
	fetTalentsByIDs: "/admin/fetchTalentsByIds",

	addNFT: "/admin/getData",
};

export default requests;

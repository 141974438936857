import API from "./config"
import jwt_decode from "jwt-decode"

export const authenticate = (data, next) => {
    if (typeof window !== "undefined") {
        localStorage.setItem("jwt", JSON.stringify(data));
        next();
    }
};

export const isAuthenticated = () => {
    if(localStorage.getItem("jwt")){
        let {obj:{data}} = JSON.parse(localStorage.getItem("jwt"));
        const decodedToken = jwt_decode(data)
        if (decodedToken.exp * 1000 < Date.now()) {
            localStorage.clear();
        } 
    }
    if (typeof window == "undefined") {
        return false;
    }
    if (localStorage.getItem("jwt")) {
        return JSON.parse(localStorage.getItem("jwt"));
    } else {
        return false;
    }
};

export const LogoutApi = (next) => {
    if (typeof window !== "undefined") {
      localStorage.removeItem("jwt");
      next();
    }
  };


import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import App from "../../App";
import { isAuthenticated } from "../../requests";
import Toggle from "react-toggle";
import "react-toggle/style.css";
import Table from "../Table";
import { Instance, setAuthToken } from "../../axios";
import axios from "axios";
import requests from "../../request";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import API_URL from "../../config";

const Rewards = () => {
  let {
    obj: { data },
  } = isAuthenticated();
  let token = data;
  let awsLINK =
    "https://sandboxstaticfiles.s3.ap-southeast-1.amazonaws.com";

  const [rewards, setRewards] = useState([]);
  const [forceReload, setForceReload] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchRewards = async () => {
      setLoading(true);
      setAuthToken(token);
      const response = await Instance.get(requests.fetchAllRewards).catch(
        (error) => {
          if (error.response) {
            toast.error(error.response.data.error.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        }
      );

      if (response && response.data) {
        let newArr = [];
        response.data.data.forEach((data) => {
          let newObj = {};
          newObj["coverImage"] = `${awsLINK}/${data["cover_image"][0]}`;
          newObj["name"] = data.rewards.name ? data.rewards.name : "N/A";
          newObj["nft"] = data.rewards.nft ? data.rewards.nft : "N/A";
          newObj["type"] = data.rewards.type ? data.rewards.type : "N/A";
          // newObj["nameuser"] = "Alexandra"
          newObj["status"] = data.isRedeemed ? "Redeemed" : "Unredeemed";
          if (data.isStatus == null) {
            newObj["statusToggle"] = { status: 0, _id: data["rewardsId"] };
          } else {
            newObj["statusToggle"] = {
              status: data.isStatus,
              _id: data["rewardsId"],
            };
          }
          newObj["_id"] = data["rewardsId"];
          newArr.push(newObj);
        });
        setLoading(false);
        setRewards(newArr);
      }
    };

    fetchRewards();
  }, [forceReload]);

  const imgCol = (srcLoc) => (
    <div className="col-1 p-0">
      <img
        src={srcLoc}
        alt="No image found!"
        style={{ height: "50px", width: "90px", borderRadius: "10px" }}
      />
    </div>
  );

  const handleToggle = async (param) => {
    let status = param.status == 1 ? 0 : 1;
    let newObj = { isSend: 0, rewardId: param._id, status };
    let result = await axios.put(`${API_URL}/admin/sendRewards`, newObj, {
      headers: {
        Accept: "application/json",
        token: `${token}`,
      },
    });
    if (result.data.status === "success") {
      toast.success("Status updated successfully", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setForceReload(!forceReload);
    }
  };

  const displayToggle = (props) => (
    <div style={{ paddingTop: "15px" }}>
      <Toggle
        onChange={() => handleToggle(props.value)}
        style={{ paddingTop: "15px" }}
        defaultChecked={props.value.status == 1}
        name="milkIsReady"
        value={props.value.status}
      />
    </div>
  );

  const sendReward = async (rewardId) => {
    let newObj = { isSend: 1, rewardId };

    setAuthToken(token);
    let response = await Instance.put(requests.sendReward, newObj).catch(
      (error) => {
        if (error.response) {
          toast.error(error.response.data.error.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      }
    );

    if (response && response.data) {
      if (response.data.warning) {
        toast.error(response.data.warning, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.success("Reward has been sent!", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    }
  };

  const editSend = (props) => (
    <>
      <div className="tableData paddingTop1 editSendButton">
        <div>
          <Link
            to={`/admin/rewards/edit/${props}`}
            style={{ borderRadius: "5px" }}
          >
            <button className="btn btn-sm btn-dark px-3">EDIT</button>
          </Link>
        </div>
        &nbsp;
        <div>
          <Link to="#" className="editButton" style={{ display: "inline" }}>
            <button
              onClick={() => sendReward(props)}
              type="button"
              className="btn btn-sm btn-dark px-3"
            >
              SEND
            </button>
          </Link>
        </div>
      </div>
    </>
  );

  // const tableDatas = [
  //     {
  //         coverImage:"",
  //         awards:"HELLO",
  //         nft:"H",
  //         type:"3",
  //         nameuser:"H",
  //         status:"unredeemed",
  //         statusToggle:"1"
  //     },
  //     {
  //         coverImage:"",
  //         awards:"HELLO",
  //         nft:"H",
  //         type:"3",
  //         nameuser:"H",
  //         status:"unredeemed",
  //         statusToggle:"0"
  //     },
  // ]

  const tableColumns = [
    {
      Header: "",
      accessor: "coverImage",
      Cell: (props) => imgCol(props.value),
    },
    {
      Header: "Reward Name",
      accessor: "name",
      Cell: (props) => <p className="paddingTop">{props.value}</p>,
    },
    {
      Header: "NFT",
      accessor: "nft",
      Cell: (props) => <p className="paddingTop">{props.value}</p>,
    },
    {
      Header: "Type",
      accessor: "type",
      Cell: (props) => <p className="paddingTop">{props.value}</p>,
    },
    // {
    //     Header:"Name User...",
    //     accessor:"nameuser",
    //     Cell: (props)=><p className="paddingTop">{props.value}</p>
    // },
    {
      Header: "Status",
      accessor: "status",
      Cell: (props) => <p className="paddingTop">{props.value}</p>,
    },
    {
      Header: "Status",
      accessor: "statusToggle",
      Cell: (props) => displayToggle(props),
    },
    {
      Header: "",
      accessor: "_id",
      Cell: (props) => editSend(props.value),
    },
  ];

  return (
    <App>
      {rewards && rewards.length > 0 ? (
        <Table
          title="Rewards"
          btnLink="/admin/rewards/add"
          btnName="ADD REWARD"
          data={rewards}
          columns={tableColumns}
        />
      ) : (
        <div className="container1 mt-5">
          <div className="row">
            <div className="col-md-7">
              <h3 className="p-2">Rewards</h3>
            </div>
            <div className="col-md-5">
              <div className="row">
                <div className="col-md-7">
                  {/* <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} /> */}
                </div>
                <div className="col-md-5 text-center">
                  <Link
                    to="/admin/rewards/add"
                    type="button"
                    className="btn btn-success floatRight px-4 my-1"
                  >
                    <p className="p-0 m-0">ADD REWARD</p>
                  </Link>
                </div>
              </div>
            </div>
            <div style={{ width: "100%" }} className="text-center mt-5">
              <h4>No Records Found</h4>
            </div>
          </div>
        </div>
      )}
      <ToastContainer autoClose={2000} />
    </App>
  );
};

export default Rewards;

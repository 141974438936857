export const ADDRESSES = {
  1: "0x0cef6aecc972bc49407c7c956ee5e22daa9f0851",
  5: "0x35fa0a29b65dfc93dd5a15d4c0b1a41c4a25bdec",
};
export const OPENSEA_URL = {
  1: "api.opensea.io",
  5: "testnets-api.opensea.io",
};

export const OPENSEA_API_KEY = "f7552e6a92c74d06843d09feee99689f";
